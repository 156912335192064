import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
// import moment from 'moment';
import web_common_checkToken
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/web_common_checkToken';
import HeadContent from "./component/headContent/index.vue";

export default {
    data() {
        return {
            list: [
                {text: '分销商注册', name: 'distributor-registration'},
                {text: '供应商注册', name: 'supplier-registration'},
            ],
            navShow: false
        }
    },
    methods: {
        __btn_home_click() {
            window.location.href = '/main'
        }
    },
    components: {HeadContent},
    mounted() {
        const __this = this;
        web_common_checkToken().then(
            function (res) {
                const route_name = __this.$route.name;
                const route_name_list = [
                    `root`,
                    `login`,
                ];
                if (_.contains(route_name_list, route_name)) {
                    //如果是登录页面，永远显示没有登录用户名称的那一行头部
                    __this.navShow = false
                } else {
                    __this.navShow = true
                }
            },
            function () {
                __this.navShow = false
            }
        )
    }
}
